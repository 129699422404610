import {
    SESSION_REQUEST_ID,
    NUMBER_FORMAT,
    IQ_ALPS_COOKIE_EXPIRY_TIME,
    ALPS_LOCAL_STORAGE_KEY,
    ALPS_SELECTED_LOCALE_KEY,
    TYPEFORM_LOCAL_STORAGE_KEY,
    COOKIE_SUB_PLAN,
    COOKIE_LFT_USER,
    KW_RESEARCH_PAGE_SEARCH_TYPES
} from "../const";
import {
    isNull,
    map,
    filter,
    find,
    keys,
    isUndefined,
    isNaN,
    omit,
    get,
    isEqual,
    invert,
    split,
    uniqBy,
    slice,
    toUpper,
    trim
} from "lodash";
import { AxiosRequestConfig } from "axios";
import {
    CalculatedDateInterface,
    SerpResponseInterface,
    FormattedUrlRankListInterface,
    CompetitorAndTargetURLsListInterface,
    FilterListInterface,
    ProjectMetaDataInResponseInterface,
    MultiKeywordTrackRequestApiRequestIdsResponseInterface,
    MultiKeywordRequestIdsApiResponseInterface,
    NotifyErrorInterface,
    SerpListProgressStatusType,
    ErrorType,
    OptionalValueInterface,
    LocaleInterface,
    MultiKeywordTrackRequestApiRequestIdStatusResponseInterface,
    FlowTypeInterface
} from "./types";
import { OptionTypeInterface } from "../styledComponents/drop-down/types";
import { MessageType } from "../styledComponents/general-message/messaging-provider-hoc";
import { COPY } from "../../app/const";
import { EditorElementInterfaceBeta } from "../../beta/simulator/ducks/types";

interface AxiosParamsInterface extends AxiosRequestConfig, NotifyErrorInterface { }

export const formatDate = (date: string) => {
    const newDate = new Date(date);
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const day = newDate.getDate();
    const monthIndex = newDate.getMonth();
    const year = newDate.getFullYear();

    return `${day} ${monthNames[monthIndex]} ${year}`;
};
export const formatTime = (date: string) => {
    const newDate = new Date(date);
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const day = newDate.getDate();
    const monthIndex = newDate.getMonth();
    const year = newDate.getFullYear();

    return `${monthNames[monthIndex].slice(0, 3)} ${day}, ${year}, ${`0${newDate.getHours()}`.slice(-2)}:${`0${newDate.getMinutes()}`.slice(
        -2
    )}:${`0${newDate.getSeconds()}`.slice(-2)} (UTC)`;
};
export const dateDiff = (date1: string, date2: string): number => {
    const startDate = new Date(date1);
    const endDate = new Date(date2);
    const diffTime = Math.abs(startDate.getTime() - endDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
};
export const durationLeft = (startDate: string, endDate: string): CalculatedDateInterface => {
    const sdt: Date = new Date(startDate);
    const edt: Date = new Date(endDate);
    // @ts-ignore
    const difdt = new Date(edt - sdt);
    return {
        D: difdt.getDate(),
        M: difdt.getMonth() + 1,
        // @ts-ignore
        Y: difdt.toISOString().slice(0, 4) - 1970
    };
};
export const getMetaData = () => {
    return Object.assign({}, JSON.parse(window.localStorage[ALPS_LOCAL_STORAGE_KEY] || "{}"));
};
export const getSelectedLocaleData = () => {
    return Object.assign({}, JSON.parse(window.localStorage[ALPS_SELECTED_LOCALE_KEY] || "{}"));
};
// tslint:disable-next-line:no-any
export const downloadCSVFile = (data: any, filename: string): void => {
    /**
     * This function should be removed from UI
     * Link should be exposed from BE on click of it download should proceed
     */
    const blobData = data;
    const blob = new Blob([blobData], {
        type: "application/csv"
    });
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename || "report.csv");
    tempLink.dispatchEvent(
        new MouseEvent(`click`, {
            bubbles: true,
            cancelable: true,
            view: window
        })
    );
};

export const isValidDomain = (args: string): boolean => {
    const pattern = new RegExp(
        /\(?(?:(http|https|ftp):\/\/)?(?:((?:[^\W\s]|\.|-|[:]{1})+)@{1})?((?:www.)?(?:[^\W\s]|\.|-)+[\.][^\W\s]{2,4}|localhost(?=\/)|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::(\d*))?([\/]?[^\s\?]*[\/]{1})*(?:\/?([^\s\n\?\[\]\{\}\#]*(?:(?=\.)){1}|[^\s\n\?\[\]\{\}\.\#]*)?([\.]{1}[^\s\?\#]*)?)?(?:\?{1}([^\s\n\#\[\]]*))?([\#][^\s\n]*)?\)?/,
        "ig"
    );
    const match = args.match(pattern);
    return !isNull(match) && match[0] === args;
};

export const isValidDomainUrl = (args: string): boolean => {
    const pattern = new RegExp(
        /\(?(?:(http|https|ftp):\/\/)(?:((?:[^\W\s]|\.|-|[:]{1})+)@{1})?((?:www.)?(?:[^\W\s]|\.|-)+[\.][^\W\s]{2,4}|localhost(?=\/)|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::(\d*))?([\/]?[^\s\?]*[\/]{1})*(?:\/?([^\s\n\?\[\]\{\}\#]*(?:(?=\.)){1}|[^\s\n\?\[\]\{\}\.\#]*)?([\.]{1}[^\s\?\#]*)?)?(?:\?{1}([^\s\n\#\[\]]*))?([\#][^\s\n]*)?\)?/,
        "ig"
    );
    const match = args.match(pattern);
    return !isNull(match) && match[0] === args;
};

export const round = (num: number, place?: number) => {
    return !place ? Math.round(num * 100) / 100 : Math.round(num * Math.pow(10, place)) / Math.pow(10, place);
};
export const magnitude = (num: number) => {
    return Math.abs(num);
};

export const updateRequestId = (args: string) => {
    sessionStorage.setItem(SESSION_REQUEST_ID, args);
};

export const getRequestId = (): string => {
    const reqId = sessionStorage.getItem(SESSION_REQUEST_ID);
    return isNull(reqId) ? "" : reqId;
};

export const roundOff = (args: number) => {
    return isNull(args) ? 0 : Math.round(args * 100) / 100;
};
export const googleTagManagerDataLayer = (action: string, value: string[], optionalValue?: OptionalValueInterface) => {
    if (action) {
        if (!isUndefined(optionalValue)) {
            dataLayer.push({ event: action, ...optionalValue });
        } else {
            const eventName =
                isEqual(action, "content-brief-selectall") || isEqual(action, "content-brief-unselectall")
                    ? `${action}${value.join("--")}`
                    : `${action}--${value.join("--")}`;
            dataLayer.push({ event: eventName });
        }
    } else {
        dataLayer.push({
            event: `${value.join("--")}`
        });
    }
    return dataLayer;
};
export const logOut = () => {
    const tenantCode = getMetaData().tenant_code;
    window.location.href = `${process.env.ROOT_BASE_URL}/${tenantCode}/logout`;
};

export const kFormatter = (value: number, roundBy: number = 2, addSign: boolean = false, abbrev = NUMBER_FORMAT): string => {
    let origValue: number = Math.abs(value);
    let formattedNumber: string = round(origValue, roundBy).toString();
    // Enumerate number abbreviations

    // Go through the array backwards, so we do the largest first
    for (let i = abbrev.length - 1; i >= 0; i -= 1) {
        // Convert array index to "1000", "1000000", etc
        const size = Math.pow(10, (i + 1) * 3);
        // If the number is bigger or equal do the abbreviation
        if (size <= origValue) {
            // This gives us nice rounding to a particular decimal place.
            origValue = round(origValue / size, roundBy);
            // Handle special case where we round up to the next abbreviation
            if (origValue === 1000 && i < abbrev.length - 1) {
                origValue = 1;
                i += 1;
            }
            // Add the letter for the abbreviation
            formattedNumber = `${origValue}${abbrev[i]}`;
            break;
        }
    }
    if (addSign) {
        if (round(value, roundBy) > 0) {
            formattedNumber = `+${formattedNumber}`;
        } else if (round(value, roundBy) < 0) {
            formattedNumber = `-${formattedNumber}`;
        }
    }
    return formattedNumber;
};

interface BrowserInfo {
    name: string;
    version: string;
}

export const getBrowserInfo = (): BrowserInfo => {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
        tem = ua.match(/\bOPR\/(\d+)/);
        if (tem !== null) {
            return { name: "Opera", version: tem[1] };
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    tem = ua.match(/version\/(\d+)/i);
    if (tem !== null) {
        M.splice(1, 1, tem[1]);
    }
    return {
        name: M[0],
        version: M[1]
    };
};

export const getUrlRankList = (compatitorList: SerpResponseInterface[]): FormattedUrlRankListInterface[] => {
    const filteredList = filter(compatitorList, (competitor: SerpResponseInterface) => competitor.is_organic);
    const list: FormattedUrlRankListInterface[] = map(filteredList, (compatitor: SerpResponseInterface) => {
        return {
            rank: compatitor.organic_rank,
            url: compatitor.url
        };
    });
    return list;
};

export const getViewALPSStatus = (
    targets: CompetitorAndTargetURLsListInterface,
    competitors: CompetitorAndTargetURLsListInterface
): boolean => {
    const targetUrlWhichIsDone = find(keys(targets), (url: string) => {
        const encodedURL = encodeURI(decodeURI(url));
        const finalURL = targets[encodedURL] ? encodedURL : url;
        return targets[finalURL].status === SerpListProgressStatusType.DONE;
    });
    const competitorUrlWhichIsDone = find(keys(competitors), (url: string, key: string) => {
        const encodedURL = encodeURI(decodeURI(url));
        const finalURL = competitors[encodedURL] ? encodedURL : url;
        return competitors[finalURL].status === SerpListProgressStatusType.DONE && finalURL !== targetUrlWhichIsDone;
    });
    return !isUndefined(targetUrlWhichIsDone) && !isUndefined(competitorUrlWhichIsDone);
};

// tslint:disable-next-line:no-any
export const getAllCompetitorsStatus = (serpResponse: any): string => {
    const competitors = serpResponse.data.task.competitor_urls;
    const allCompetitorUrlStatus = map(keys(competitors), (url: string) => {
        if (competitors[url].status === SerpListProgressStatusType.DONE || competitors[url].status === SerpListProgressStatusType.FAILED) {
            return true;
        }
        return false;
    });
    if (!allCompetitorUrlStatus.includes(false)) {
        return SerpListProgressStatusType.DONE;
    }
    return serpResponse.data.status;
};

export const updateSerpList = (competitorAndTargetURLsList: CompetitorAndTargetURLsListInterface, serpList: SerpResponseInterface[]) =>
    map(serpList, (serpRow: SerpResponseInterface) => {
        const url = encodeURI(decodeURI(serpRow.url));
        const finalURL = competitorAndTargetURLsList[url] ? url : serpRow.url;
        return {
            ...serpRow,
            status: competitorAndTargetURLsList[finalURL] ? competitorAndTargetURLsList[finalURL].status : serpRow.status
        };
    });

export const updateSerpListWhenTrackStop = (serpList: SerpResponseInterface[]): SerpResponseInterface[] => {
    const newSerpList = map(serpList, (serp: SerpResponseInterface) => {
        if (serp.status !== SerpListProgressStatusType.DONE) {
            return {
                ...serp,
                status: SerpListProgressStatusType.FAILED
            };
        }
        return serp;
    });
    return newSerpList;
};
export const getFormattedUrlList = (urlList: CompetitorAndTargetURLsListInterface): OptionTypeInterface[] => {
    const formattedUrl = map(keys(urlList), (url: string) => {
        const encodedURL = encodeURI(decodeURI(url));
        const finalURL = urlList[encodedURL] ? encodedURL : url;
        return {
            isDisabled: urlList[finalURL].status !== SerpListProgressStatusType.DONE,
            label: url,
            rank: urlList[finalURL].rank,
            status: urlList[finalURL].status,
            value: url
        };
    });
    return formattedUrl;
};
export const encodeString = (str: string) => {
    return encodeURIComponent(str.replace(/%/g, "~~pct~~"));
};

export const decodeString = (str: string) => {
    const s = decodeURIComponent(str);
    return s.replace(/~~pct~~/g, "%");
};

export const toBoolToYesNo = (args: boolean): string => {
    return args ? "Yes" : "No";
};

export const isWholeNumber = (num: string | number): boolean =>
    String(num).indexOf(".") === -1 && String(num).indexOf("-") === -1 && parseFloat(num.toString()) % 1 === 0 && !isNaN(Number(num));

export const isPositiveFractionalNumber = (num: string | number): boolean => String(num).indexOf("-") === -1 && !isNaN(Number(num));

export const toDecimal = (num: string, decimal: number): string => {
    const t = num;
    return t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), decimal + 1) : t;
};
export const getDisableFilter = (availableImortance: string[], filterList: FilterListInterface[]) => {
    return map(filterList, (list: FilterListInterface) => ({
        ...list,
        disabled: availableImortance.indexOf(list.value) > -1 ? false : true
    }));
};

/**
 * Gives list of partial processed keywords from Multiple Keyword Track API response
 *
 * @param {MultiKeywordTrackRequestApiRequestIdsResponseInterface} keywords - Multikeyword Track API response
 * @param {MultiKeywordRequestIdsApiResponseInterface} allKeywords - Multikeyword Initate API response
 * @returns {string[]} - Partial processed keywords.
 *
 */
export const getProcessedKeywordsName = (
    keywords: MultiKeywordTrackRequestApiRequestIdsResponseInterface,
    allKeywords: MultiKeywordRequestIdsApiResponseInterface
): string[] => {
    const keywordsWithId = map(keywords, (k: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface, id: string) => ({ ...k, id }));
    const processedKeywords = filter(keywordsWithId, ["is_my_url_processed", true]);
    const kwWithId = invert(allKeywords);
    const processedKeywordName = map(processedKeywords, (v: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface) => {
        return kwWithId[v.id as string];
    });
    return processedKeywordName;
};

const getErrType = (errors: ErrorType[], status: number): ErrorType | null => {
    let errObj = null;
    if (errors.length > 0) {
        errObj = find(errors, { status });
        if (isUndefined(errObj)) {
            errObj = find(errors, { status: "default" });
            if (isUndefined(errObj)) {
                errObj = null;
            }
        }
    }
    return errObj;
};

export const notifyError = (status: number, args: NotifyErrorInterface = {}): void => {
    if ((status >= 400 && status < 600) || status === 0) {
        const { notifyErrorMethod, needToHandleError = false, isSpecificError = false, errorList } = args;
        if (needToHandleError && !isUndefined(notifyErrorMethod)) {
            let err = null;
            if (isSpecificError && !isUndefined(errorList)) {
                err = getErrType(errorList, status);
            }
            const id = !isNull(err) ? err.id : 100;
            const message = !isNull(err) ? err.errMsg : COPY.GENERIC_API_FAILURE_MSG;
            const messages = {
                id,
                message,
                type: !isNull(err) ? MessageType.ERROR : MessageType.WARNING,
                title: !isNull(err) ? COPY.ERROR_TEXT : COPY.WARNING_TEXT,
                dissolveTime: 5000,
                autoClose: !isNull(err) ? false : true,

            };
            notifyErrorMethod({ ...messages });
        }
    }
};

// tslint:disable-next-line:no-any
export const getAxiosParam = (params: AxiosRequestConfig, args: any): AxiosParamsInterface => {
    const { needToHandleError, errorList, notifyErrorMethod, isSpecificError } = args;
    const newParams = {
        ...params,
        notifyError: { needToHandleError, errorList, notifyErrorMethod, isSpecificError }
    };
    return newParams;
};

// tslint:disable-next-line:no-any
export const omitOptionalArgs = (args: any): any => {
    return omit(args, ["needToHandleError", "isSpecificError", "errorList", "notifyErrorMethod", "isBeta"]);
};

export const getCookie = (cname: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${cname}=`);
    if (parts.length === 2) {
        return parts
            ?.pop()
            ?.split(";")
            .shift();
    }
    return undefined;
};

export const getTypeformDisplay = () => {
    const typeFormData = localStorage.getItem(TYPEFORM_LOCAL_STORAGE_KEY);
    const ls = !isNull(typeFormData) ? JSON.parse(typeFormData) : {};
    const currentDate = new Date().getTime();
    const expiryDate = ls.expires ? new Date(ls.expires).getTime() : currentDate;

    if (expiryDate <= currentDate) {
        return true;
    }

    return false;
};

export const storeMetaData = (data: ProjectMetaDataInResponseInterface) => {
    // tslint:disable-next-line: no-console
    // console.log("data", data);
    /**
     * This is util to set localStorage from rank simulation flow,
     * This should be removed once our login is shifted from angular to React
     */
    const preMeta = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
    const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
    ls.projectID = data.projectId;
    ls.projectName = data.project_name;
    ls.isStandardProject = data.is_standard_project;
    ls.isMobileProject = data.is_mobile_project;
    ls.locale = data.project_locale;
    ls.searchEngine = "google";
    // we dont have provision for other search engines. when we do,
    // remove the fallback.
    localStorage.setItem(ALPS_LOCAL_STORAGE_KEY, JSON.stringify(ls));
};

export const updateCookies = (data: ProjectMetaDataInResponseInterface) => {
    const previousCookies = getCookie(`${process.env.COOKIE_SESSION_ID}`);
    if (!isUndefined(previousCookies)) {
        const cookies = {
            ...JSON.parse(previousCookies as string),
            locale: data.project_locale,
            project_id: data.projectId,
            isStandardProject: data.is_standard_project,
            isMobileProject: data.is_mobile_project,
            projectName: data.project_name,
            projectID: data.projectId
        };
        document.cookie = `${process.env.COOKIE_SESSION_ID} = ${JSON.stringify(cookies)}; expires=${new Date(
            new Date().getTime() + IQ_ALPS_COOKIE_EXPIRY_TIME
        ).toUTCString()}; path=/`;
    }
};

export const downloadToCsv = (catReport: string, fileName: string): void => {
    const blob = new Blob([catReport], { type: "text/csv" });
    const url2 = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", " ");
    a.setAttribute("href", url2);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

// tslint:disable-next-line:no-any
export const downloadToXlsx = (excelBuffer: any, fileName: string) => {
    const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    });
    const url2 = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", " ");
    a.setAttribute("href", url2);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

// tslint:disable-next-line:no-any
export const onLeavePage = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
};

export const isBeginsURLPattern = (text: string) => {
    const pattern = /^((http|https)\:\/\/)[^\s]*$/;
    return pattern.test(text);
};

export const isDomain = (text: string) => {
    const pattern = /\(?(?:(http|https):\/\/)(?:((?:[^\W\s]|\.|-|[:]{1})+)@{1})?((?:www.)?(?:[^\W\s]|\.|-)+[\.][^\W\s]{2,4}|localhost(?=\/)|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::(\d*))?([\/]?)\)?/;
    return pattern.test(text);
};

export const isURL = (text: string) => {
    const pattern = /\(?(?:(http|https):\/\/)(?:((?:[^\W\s]|\.|-|[:]{1})+)@{1})?((?:www.)?(?:[^\W\s]|\.|-)+[\.][^\W\s]{2,4}|localhost(?=\/)|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::(\d*))?([\/]{1}[^\s\#\?][\/]{0,1})+(?:\/?([^\s\n\?\[\]\{\}\#]*(?:(?=\.)){1}|[^\s\n\?\[\]\{\}\.\#]*)?([\.]{1}[^\s\?\#]*)?)?(?:\?{1}([^\s\n\#\[\]]*))?([\#][^\s\n]*)?\)?/;
    return pattern.test(text);
};

export const maxLengthStringValidation = (value: string, max: number) => {
    if (value && max && value.length > max) {
        return {
            inputValue: value,
            isError: true
        };
    }
    return {
        inputValue: value,
        isError: false
    };
};

export const getFormattedLocales = (locales: LocaleInterface[]): OptionTypeInterface[] => {
    return map(locales, (locale: LocaleInterface) => ({
        label: locale.country,
        language: locale.language,
        name: locale.name,
        value: locale.code
    }));
};

export const getFeatureAddons = () => {
    const preMeta = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
    const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
    const featureAddOns = get(ls, ["featureAddOns"], []);
    return featureAddOns;
};

export const isTooltipShow = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element && element.scrollWidth > element.clientWidth) {
        return true;
    }
    return false;
};

export const isTrialUser = () => {
    let isTrial = false;
    document.cookie.split(";").forEach((el) => {
        const [key, value] = el.split("=");
        if (key.trim() === "__iQ_ALPS_TRIAL_USER" && value.toLowerCase() === "true") {
            isTrial = value.toLowerCase() === "true";
        }
    });
    return isTrial;
};

export const isLFTUser = () => {
    let isLFT = false;
    document.cookie.split(";").forEach((el) => {
        const key = el.split("=")[0];
        if (key.trim() === COOKIE_SUB_PLAN || key.trim() === COOKIE_LFT_USER) {
            isLFT = true;
        }
    });
    return isLFT;
};

export const isValidUser = () => {
    let isValid = false;
    const linkItems = window.location.href.split("/");
    if (
        linkItems.includes("alps") &&
        linkItems.includes("links") &&
        !isUndefined(linkItems[linkItems.indexOf("links") + 1]) &&
        linkItems[linkItems.indexOf("alps") - 1].includes(".com")
    ) {
        isValid = true;
    }
    return isValid;
};

export const isUnAuthorized = () => {
    let isAuthorized = false;
    const linkItems = window.location.href.split("/");
    if (linkItems.includes("verify") && linkItems.includes("user") && !isUndefined(linkItems[linkItems.indexOf("user") + 1])) {
        isAuthorized = true;
    }
    return isAuthorized;
};
export const isExpireUser = () => {
    let isExpire = false;
    const linkItems = window.location.href.split("/");
    if (linkItems.includes("expireuser")) {
        isExpire = true;
    }
    return isExpire;
};

export const getUserId = () => {
    let linksText = "";
    let linkId = "";
    window.location.href.split("/").forEach((el: string) => {
        if (linksText === "links") {
            linkId = el;
        }
        linksText = el;
    });
    return linkId;
};

export const getBaseURL = (path: string) => {
    let baseURL = "";
    window.location.href.split("/").forEach((el: string) => {
        if (el.includes(".com")) {
            baseURL = el;
        }
    });
    return `${location.protocol}//${baseURL}/${path}`;
};

export const downloadLatestInfotainmentLoaderData = async () => {
    const response = await fetch(`${process.env.IMAGE_URL}/migrated_alps/infotainment/latest.json`);
    const responseJson = await response.json();
    if (response.status === 200) {
        return responseJson;
    }
    return undefined;
};

/**
 * Remove http, https, www. from the url
 *
 * @param {string} Url
 * @returns {string} - Trimmed URL
 *
 */
export const getTrimmedURL = (Url: string) => {
    return Url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
};

export const getTitle = (pageContent: EditorElementInterfaceBeta[], defaultFileName: string, perfix: string) => {
    let title: string = "";
    map(pageContent, (item) => {
        if (item.title === "title" && typeof item.contents === "string") {
            title = item.contents.replace(/[^\w\u4e00-\u9fff]/ig, "_");
        } else if (item.title === "title" && typeof item.contents === "object") {
            title = item.contents.join("_").replace(/[^\w\u4e00-\u9fff]/ig, "_");
        }
    });
    return title.length > 0 ? `${perfix}${title.replace("__", "")}.docx` : defaultFileName;
};

export const getInputType = (input: string) => {
    let inputType: string = KW_RESEARCH_PAGE_SEARCH_TYPES.keyword;
    if (isURL(input)) {
        inputType = KW_RESEARCH_PAGE_SEARCH_TYPES.url;
    } else if (isDomain(input)) {
        // AL-7402 | Remove Domain KRT
        inputType = KW_RESEARCH_PAGE_SEARCH_TYPES.url;
    }
    return inputType;
};

export const getPlaceholderText = (flowType?: string) => {
    let placeHolderText;

    switch (flowType) {
        case FlowTypeInterface.PAGE_CONTENTBRIEF:
            placeHolderText = COPY.SEARCH_TOPIC_PLACEHOLDER_TEXT_CONTENTBRIEF;
            break;
        case FlowTypeInterface.PAGE_OPTIMIZER:
            placeHolderText = COPY.SEARCH_URL_PLACEHOLDER_TEXT_PAGE_OPTIMIZER;
            break;
        default:
            placeHolderText = COPY.SEARCH_URL_PLACEHOLDER_TEXT;
            break;
    }
    return placeHolderText;
};

export const getLocaleAndProjectId = (multikeywordLocale?: string, contextLocale?: string) => {
    const locale = JSON.parse(localStorage.getItem("selected_locale") as string);
    const { default_project, projectID } = JSON.parse(localStorage.getItem("ls.__iQ_alps") as string);
    return { locale, default_project, projectID };
};

export const getFormattedKeywords = (defaultKeyword: string, selectedKeywordsList: string[], keywordsInput: string) => {
    // spliting the comma seperated string to string[]
    const keywordsInputList: string[] = split(keywordsInput, ",");
    // trimming the whitespace in every keyword
    const trimmedKeywordsList: string[] = map(keywordsInputList, (key: string) => trim(key));
    // filtering out the emply keywords
    const filteredKeywordsList: string[] = filter(trimmedKeywordsList, (keyword: string) => keyword.length > 0);
    // this will return the unique keywords in the order: selected keywords followed by new keywords
    const uniqueKeywordList = uniqBy([defaultKeyword, ...selectedKeywordsList, ...filteredKeywordsList], toUpper);
    // removing the default keyword
    return slice(uniqueKeywordList, 1);
};

export const getNewSelectedKeywords = (
    defaultKeyword: string,
    selectedKeywordsList: string[],
    keywordsInput: string,
    maxAllowedLimit: number
) => {
    const formattedKeywordList = getFormattedKeywords(defaultKeyword, selectedKeywordsList, keywordsInput);

    if (formattedKeywordList.length > maxAllowedLimit) {
        // if the keywords length is exceeding then
        // extract by ignoring the additional keywords
        // the formattedKeywordList will maintain the order of existing keywords followed by new keywords
        return slice(formattedKeywordList, 0, maxAllowedLimit);
    }
    return formattedKeywordList;
};


/*Function to combine reducers into flat structure*/
// tslint:disable-next-line: no-any
export const composeReducers = (firstReducer: any, secondReducer: any) => (state: any, action: any) => {
    const combinedReducers = [firstReducer, secondReducer];

    // tslint:disable-next-line: no-any
    return combinedReducers.reduce((currentState: any, reducer: any) => reducer(currentState, action), state);
};

export const updateIdName = (site: string | null | undefined) => {
    let name;
    if (!isUndefined(site) && !isNull(site)) {
        name = site.split(" ").join("_");
    }
    return name;
};
